<template>
    <div class="ui stackable grid m-0">
        <div class="row border-bottom">
            <div class="eight wide left aligned column">
                <h1 class="ui grey header"> Editter Dispatcher </h1>
            </div>
            <div class="eight wide right aligned column">
                <div class="ui primary tiny button" @click="save">
                    <i class="check circle icon"></i>
                    Enregistrer
                </div>

                <div class="ui button" @click="$router.push({name: 'folderList'})">
                    <i class="times circle icon"></i>
                    Cancel
                </div>
            </div>
        </div>

        <div class="row">
            <div class="column">
                    <div class="ui form">
                        <div class="ui  dividing tiny grey header">Informations dossier</div>
                        <div class="two fields">
                            <div class="field">
                                <label>N° dossier</label>
                                <input type="text" placeholder="N° dossier" :value="folder.numero" disabled>
                            </div>

                            <div class="field">
                                <label>Immatriculation</label>
                                <input type="email" placeholder="Immatriculation"
                                       :value="folder.vehicule ? folder.vehicule.n_immat : ''" disabled>
                            </div>

                        </div>

                        <div class="two fields">

                            <div class="field">
                                <label>Date de création</label>
                                <input type="text" placeholder="Date de création" disabled
                                       :value="$store.getDate(folder.date_creation, 'DD-MM-YYYY')">
                            </div>

                            <div class="field">
                                <label> Crée par </label>
                                <input type="tel" placeholder="Crée par" :value="folder.user.username" disabled >
                            </div>

                        </div>
                        <div class="ui  dividing tiny grey header">Dispatcher</div>
                        <div class="field" :class="{'error': $v.selected_dispatcher.$error}">
                            <label> Dispatcher </label>
                            <!--<v-select :options="dispatcher_list" v-model="$v.selected_dispatcher.$model"
                                      label="username" style="text-transform: capitalize" placeholder="Dispatcher">
                            </v-select>-->
                            <UserGroupSelect v-model="$v.selected_dispatcher.$model" :filterOut="{type:'dispatcher', _id:{$ne:folder_prop.dispatcher._id}}"></UserGroupSelect>
                            <!-- error message -->
                            <div class="ui negative message" v-if="$v.selected_dispatcher.$error">
                                <p><i class="info circle icon"></i> Dispatcher est Obligatoire. </p>
                            </div>

                        </div>
                    </div>
            </div>
        </div>

    </div>
</template>

<script>
    import UserGroupSelect from "@/components/UserGroupSelect.vue";
    import { required, minLength, maxLength } from 'vuelidate/lib/validators'
    export default {
        name: "folderEdit_dispatcher",
        components:{
            UserGroupSelect
        },
        props:{
            folder_prop: {
                type: Object,
                default(){
                    return {}
                }
            }
        },
        data(){
          return{
              isEdit: false,
              selected_dispatcher: {},
              dispatcher_list: [],
              folder: {}
          }
        },
        methods:{
            save(){
                log('save');
                this.$v.$touch();
                if (this.$v.$invalid)
                    return;

                const data = {
                    id_doc: this.folder._id,
                    old_dispatcher: this.folder.dispatcher._id,
                    new_dispatcher: this.selected_dispatcher
                };
                const url = this.$SHARED.services.document.updateDispatcher;
                this.$store.dispatchAsync(url, data).then(() => {
                    this.$router.push({name: 'folderList'});
                    this.$notify({
                        group: 'user-message',
                        type: 'success', // warn , error, success, info
                        text: this.$SHARED.messages.document.updateDispatcher_succeeded
                    });
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                });
            },
            getDispatcher() {
                console.log('getDispatcher');
                const data = {
                    query: {type: 'dispatcher'},
                    options: {page: 1, limit: -1}
                };
                this.$store.dispatchAsync(this.$SHARED.services.user.list, data).then(ar => {
                    this.dispatcher_list = ar.docs;
                }, code => {
                    this.$notify({
                        group: 'user-message',
                        type: 'error', // warn , error, success, info
                        text: this.$SHARED.messages.technical_error
                    });
                })
            },
        },
        validations: {
            selected_dispatcher: {required}
        },
        mounted() {
            this.folder = JSON.parse(JSON.stringify(this.folder_prop));
            this.getDispatcher();
        }
    }
</script>

<style scoped>
    .border-bottom{
        border-bottom: 2px solid #767676;
    }
</style>