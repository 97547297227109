<template>
    <v-select
            class="style-chooser"
            label="username"
            :options="user_list"
            v-model="selected_user"
            :filterable="true"
            :placeholder="placeholder"
    >
        <template slot="option" slot-scope="option">

            <table style="border-spacing:0px">
                <tbody>
                <tr>
                    <td rowspan="2"><i class="large user circle outline icon"></i></td>
                    <td>
                        <b>{{ option.username }}</b>
                        <br><i>{{option.lastname}} {{option.firstname}}</i>
                    </td>
                </tr>
                </tbody>
            </table>

        </template>
        <template slot="no-options">
            Tapez le nom d'utilisateur / département
        </template>
    </v-select>
</template>

<script>

    export default {
        name: "UserGroupSelect",
        props:['return','filterOut', 'filterFront', 'placeholder', 'value'],
        data(){
            return {
               selected_user:null,
                all_users:[]
            }
        },
        methods:{
            getUsers() {
                console.log('getUsers');
                let query= this.filterOut ? this.filterOut : {}
                const data = {query: query, options: {page: 1, limit: -1, sort: {username: 1}}};
                this.$store.dispatchAsync(this.$SHARED.services.user.list, data).then(ar => {
                    this.all_users = ar.docs;
                }, code => {
                    this.$notify({group: 'user-message', type: 'error',text: this.$SHARED.messages.technical_error});
                })
            },
        },
        computed:{
            user_list(){
                return this.filterFront ? _.filter(this.all_users, this.filterFront) : this.all_users;
            }
        },
        watch:{
            selected_user(newVal, oldVal){
                let final_result = null;
                if(this.selected_user !==null){
                    final_result = this.return ? this.selected_user[this.return] : this.selected_user
                }
                this.$emit('input', final_result)
            },
            value(){
                if(!this.value){
                    this.selected_user = null;
                }
            }
        },
        mounted() {
            this.getUsers();
        }
    }
</script>

<style scoped>

</style>